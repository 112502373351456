<template>
  <div class="status-box" :class="{'-completed': completedState, '-in-progress': inProgressState}">
    <div>
      <div class="status-icon  mb-m  mb-xs-0">
        <slot name="icon"></slot>
      </div>
      <div class="status-content">
        <slot name="content"></slot>
      </div>
    </div>
    <div class="status-tag">
      <hr class="color-blue-100  mt-s  mb-s">
      <Tag v-if="inProgressState" class="-slim  -orange  -no-margin-bottom">
        <slot name="in-progress"></slot>
      </Tag>
      <Tag v-else-if="completedState" class="-slim  -green  -no-margin-bottom">
        <slot name="completed"></slot>
      </Tag>
      <Tag v-else class="-slim  -grey  -no-margin-bottom">
        <slot name="pending"></slot>
      </Tag>
    </div>
  </div>
</template>

<script>
import Tag from '@/components/atoms/Tag.vue';

export default {
  name: 'StatusBox',
  props: {
    completedState: Boolean,
    inProgressState: Boolean,
  },
  methods: {
  },
  components: {
    Tag,
  },
};
</script>

<style scoped lang="scss" src="@/sass/08_modules/status-box.scss"></style>
