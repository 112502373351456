<template>
  <div class="status-info">
    <Background></Background>
    <div class="row  mb-l  mb-md-m  mt-s  mt-md-0  pos-relative">
      <div class="col-18  valign-middle">
        <h3 class="mb-0  font-weight-normal  color-white">{{ $t('statusInfo.headline') }}</h3>
        <h3 class="mb-0  font-weight-normal  color-white  text-uppercase">{{ $t(`groups.executive`) }}</h3>
      </div>
    </div>
    <div class="row  display-flex  flex-wrap">
      <div class="col-6  col-md-12  col-xs-24  mb-md-xs" v-for="(state, key, index) in $t('statusInfo.states')" :key="key">
        <StatusBox :completedState="index < currentStateIndex" :inProgressState="index === currentStateIndex">
          <template slot="icon" v-if="!$matchMedia.xs">
            <Icon class="-size-104">
              <component :is="`${key}SVG`"></component>
            </Icon>
          </template>
          <template slot="content">
            <h6
                class="headline">{{ index + 1 }}. {{ $t(`states.${key}.title` )}}
            </h6>
            <div class="sub-headline">{{ state.subheadline }}</div>
            <ul class="criteria-list">
              <li v-for="(criteria, key) in state.criterias" :key="key">{{ criteria }}</li>
            </ul>
          </template>
          <template slot="in-progress">
            <i18n path="states.inProgress">{{ $t(`states.${key}.title` )}}</i18n>
          </template>
          <template slot="completed">
            <Icon class="-size-14  mr-xxs">
              <CheckmarkSVG></CheckmarkSVG>
            </Icon>
            <i18n path="states.done">{{ $t(`states.${key}.title` )}}</i18n>
          </template>
          <template slot="pending">{{ $t(`states.pending` )}}</template>
        </StatusBox>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { applicationStates } from '@/values';

import CheckmarkSVG from '@/assets/icons/check.svg';
import selectionSVG from '@/assets/icons/application/selection.svg';
import sightingSVG from '@/assets/icons/application/sighting.svg';
import consolidationSVG from '@/assets/icons/application/consolidation.svg';
import decisionSVG from '@/assets/icons/application/decision.svg';
import partnerSVG from '@/assets/icons/application/partner.svg';

import Icon from '@/components/atoms/Icon.vue';
import Background from '@/components/atoms/Background.vue';
import StatusBox from '@/components/modules/User/application/StatusBox.vue';

export default {
  name: 'StatusInfo',
  data() {
    return {
      statesConfig: applicationStates,
    };
  },
  computed: {
    ...mapState([
      'status',
      'usermeta',
      'group',
    ]),

    currentStateIndex() {
      return this.statesConfig.findIndex((state) => {
        if (Array.isArray(state)) {
          return state.indexOf(this.status) >= 0;
        }

        return state === this.status;
      }) - 1;
    },
  },
  components: {
    Icon,
    CheckmarkSVG,
    selectionSVG,
    sightingSVG,
    consolidationSVG,
    decisionSVG,
    partnerSVG,
    Background,
    StatusBox,
  },
};
</script>

<style scoped lang="scss" src="@/sass/08_modules/status-info.scss"></style>
